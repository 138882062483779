import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})

export class LineService {

  // private channelId: string = '1655766980';
  // private channelSecret: string = 'f0807628d056ab2582f572a7db8ef925';
  // private redirect_uri: string = 'https://linenekocareplus.yamatothai.com/auth?openExternalBrowser=1';

  private channelId: string = '1656109608';
  private channelSecret = '481f5eadbd6c08ed50ede31805af9273';
  // private redirect_uri: string = 'http://localhost:4200/auth';
  private redirect_uri: string = 'https://mbackoffice.yamatothai.com/auth';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }
  getResponseCode() {
    window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${this.channelId}&redirect_uri=${encodeURIComponent(this.redirect_uri)}&state=${Guid.newGuid()}&scope=profile%20openid`;
  }

  getAccessToken(code: string) {
    let body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('code', code);
    body.set('redirect_uri', this.redirect_uri);
    body.set('client_id', this.channelId);
    body.set('client_secret', this.channelSecret);

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return this.http.disableApiAuthInterceptor().disableApiPrefix().post(`https://api.line.me/oauth2/v2.1/token`, body.toString(), options);
  }

  getProfile() {
    var token = this.storageService.retrieve('line_access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    })

    console.log(token);
    return this.http.disableApiAuthInterceptor().disableApiPrefix().get(`https://api.line.me/v2/profile`, { headers: headers });
  }

}


class Guid {
  static newGuid() {
    return 'xxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}