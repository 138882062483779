import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private http: HttpClient,
    ) { }

    getAccessTokenByLineUserId(lineUserId: string) {
        return this.http.disableApiAuthInterceptor().get(environment.authUrl + `/api/interface?lineId=${lineUserId}`);
    }    
    getAccessTokenByLineUserIdAndEmpId(lineUserId: string, employeeId: string) {
        return this.http.disableApiAuthInterceptor().get(environment.authUrl + `/api/interface/register?lineId=${lineUserId}&employeeId=${employeeId}`);
    }
    removeUserLineMappingsByLineUserId(lineUserId: string) {
        return this.http.disableApiAuthInterceptor().get(environment.authUrl + `/api/interface/logout?lineId=${lineUserId}`);
    }
}